<template>
    <div>
        <Banner titulo="Contacto" />
        <section id="contacto" class="container">

            <div class="col">
                <h2>Email</h2>
                <a
                    href="mailto:info@avsonline.com.ar?subject=Solicito%20informaci%C3%B3n%20de%20Audiovisual%20Systems%20SA">info@avsonline.com.ar</a>
            </div>
            <div class="col">
                <h2>Oficinas</h2>
                <ul>
                    <li><a href="tel:+541148546000">11-4854-6000</a></li>
                    <li><a href="tel:+541148545944">11-4854-5944</a></li>
                </ul>
            </div>
            <div class="col">

                <h2>Redes</h2>
                <ul class="social-links">
                    <li><a title="Whatsapp" href="https://wa.link/jzux1l" target="_blank"><i class="whatsapp"></i></a>
                    </li>
                    <li><a title="Facebook" href="https://es-la.facebook.com/AUDIOVISUALSYSTEMS" target="_blank"><i
                                class="facebook"></i></a></li>
                    <li><a title="Youtube" href="https://www.youtube.com/user/avsonline" target="_blank"><i
                                class="youtube"></i></a></li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
import Banner from "../components/Banner.vue"
import LoaderBanner from "../components/LoaderBanner.vue"
import LoaderIframe from "../components/LoaderIframe.vue"
export default {
    components: {
        LoaderIframe,
        Banner,
        LoaderBanner
    },
    data() {
        return {
            loadingImg: true,
            loading: true,
            equipamiento: [
                {
                    nombre: "Pantallas de LED para Interiores y Exteriores",
                    img: "pantallas-de-led.jpg",
                },
                {
                    nombre: "Piso de LED",
                    img: "piso-de-led.jpg",
                },
                {
                    nombre: "Live Switchers",
                    img: "live-switchers.jpg",
                },
                {
                    nombre: "Cámaras Robóticas PTZ",
                    img: "camaras-roboticas-ptz.jpg",
                },
                {
                    nombre: "Proyectores de Alta potencia",
                    img: "proyectores.jpg",
                },
                {
                    nombre: "Conversores",
                    img: "conversores.jpg",
                },
                {
                    nombre: "Distribuidores",
                    img: "distribuidores.jpg",
                },
                {
                    nombre: "Extensores",
                    img: "extensores.jpg",
                },
                {
                    nombre: "Matrices",
                    img: "matrices.jpg",
                },
                {
                    nombre: "Fibra Óptica",
                    img: "fibra-optica.jpg",
                },
                {
                    nombre: "Video Cámaras Broadcast",
                    img: "video-camaras-broadcast.jpg",
                },
                {
                    nombre: "Controles de Video y Datos",
                    img: "controles-de-video-y-datos.jpg",
                },
                {
                    nombre: "Pantallas LED Curvas y Formas Especiales",
                    img: "pantallas-led-curvas.jpg",
                },
                {
                    nombre: "Tramas y Cortinas LED",
                    img: "tramas-y-cortinas-led.jpg",
                },
                {
                    nombre: "Sistemas de Transmisión en 4K",
                    img: "sistemas-de-transmision-en-4k.jpg",
                },
                {
                    nombre: "Software de Control",
                    img: "software-de-control.jpg",
                },
                {
                    nombre: "Servers de Video",
                    img: "servers-de-video.jpg",
                },
            ]
        };
    },
    metaInfo() {
        return {
            title: "Equipamiento | Audiovisual Systems",
            meta: [{
                hid: 'description',
                name: 'description',
                content: 'Pantallas de LED para Interiores y Exteriores, Tramas y Cortinas LED, Pantallas LED Curvas y Formas Especiales, Piso de LED, Sistemas de Transmisión en 4K, Live Switchers, Video Cámaras Broadcast, Cámaras Robóticas PTZ, Monitores, Proyectores de Alta potencia, Controles de Video y Datos, Conversores, Distribuidores, Extensores, Matrices, Fibra Óptica, Servers de Video, Software de Control'
            }]
        };
    },
    methods: {
        LoadedImg() {
            this.loadingImg = false
        },
    },
    async mounted() {
        this.loading = false;
    }
};
</script>