<template>
    <div v-show="showErrorPage" class="container-error-page">
        <h2>Error 404</h2>
        <p>Esta página no existe</p>
        <router-link class="button" to="/">Volver al inicio</router-link>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showErrorPage: false
        }
    },
    metaInfo() {
        return {
            title: "Se ha producido un error"
        }
    },
    beforeMount() {
        //redireccion de viejas url de folletos
        let url = this.$route.path
        if (url.includes("/Folletos")) {
            window.location.href = `http://folletos.avs.com.ar${this.$route.path}`
        } else {
            this.showErrorPage = true
        }
    }
};
</script>
  
  