<template>
    <Shows />
</template>
<script>
import Shows from "../components/Shows.vue";
export default {
    components: {
        Shows,
    }
}
</script>
