import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import Home from '../views/Home.vue'
import Shows from '../views/Shows.vue'
import ShowsShow from '../views/ShowsShow.vue'
import Equipamiento from '../views/Equipamiento.vue'
import Contacto from '../views/Contacto.vue'
import Error from '../views/Error.vue'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/shows',
    name: 'shows',
    component: Shows
  },
  {
    path: '/shows/:ano',
    name: 'shows-ano',
    component: Shows
  }, {
    path: '/shows/:ano/:show',
    name: 'shows-show',
    component: ShowsShow
  },
  {
    path: '/equipamiento',
    name: 'equipamiento',
    component: Equipamiento
  }, {
    path: '/contacto',
    name: 'contacto',
    component: Contacto
  }, {
    path: '/:pathMatch(.*)',
    name: 'error',
    component: Error
  },

]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})



export default router
