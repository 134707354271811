<template>
  <nav>
    <ul class="container">
      <li>
        <router-link :to="preLink">Todo</router-link>
      </li>
      <li v-for="ano in anos" :key="ano.id">
        <router-link :to="`${preLink}/${ano.ano}`">{{ ano.ano }}</router-link>
      </li>
    </ul>
  </nav>
</template>


<script>
import { db } from "../../firebase-config";
import { collection, query, getDocs, orderBy } from "firebase/firestore";
export default {
  props: ["preLink"],
  data() {
    return {
      anos: []
    }
  },
  async mounted() {
    //Query años
    const queryAnos = query(collection(db, "anos"), orderBy("ano", "desc"));
    const querySnapshotAnos = await getDocs(queryAnos);
    querySnapshotAnos.forEach((doc) => {
      this.anos.push({ id: doc.id, ...doc.data() });
    });
  }
};
</script>