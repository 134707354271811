//Firebase v9
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyAqXHI-p-AYY-vf3JAq4WhD_Yz7L4_fZr8",
  authDomain: "avs-web-2021.firebaseapp.com",
  projectId: "avs-web-2021",
  storageBucket: "avs-web-2021.appspot.com",
  messagingSenderId: "736973193467",
  appId: "1:736973193467:web:0ddfa6c990e60102aab05e",
  measurementId: "G-6XPLG9WQYF"
});

export const db = getFirestore()
export const storage = getStorage();
